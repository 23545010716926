import { Switch } from '@headlessui/react'
import Gravatar from 'react-gravatar'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import BuyerLayout from '../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../components/BreadCrumb/Index'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Settings = () => {
  const [automaticTimezoneEnabled, setAutomaticTimezoneEnabled] = useState(true)
  const { user } = useSelector((state) => state.auth)
  return (
    <BuyerLayout>
      <BreadCrumb title="Settings" url={`/buyer/${user?.username}`} />
      <div className="py-8 px-4 max-w-7xl mx-auto">
        <div className="space-y-6 dark:border dark:border-gray-50 sm:px-6 lg:col-span-9 lg:px-0 bg-white dark:bg-gray-950 rounded-lg">
          <div className="p-8 mx-auto max-w-2xl space-y-16 sm:space-y-20 lg:mx-0 lg:max-w-none">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                Settings
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                This information will be displayed privately on your profile
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                    Full name
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900 dark:text-white">
                      {user.first_name}
                    </div>
                    <button
                      type="button"
                      className="font-semibold text-purple-600 hover:text-purple-500"
                    >
                      Update
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                    User Email
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900 dark:text-white">
                      {user.email}
                    </div>
                    <button
                      type="button"
                      className="font-semibold text-purple-600 hover:text-purple-500"
                    >
                      Update
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                    User Role
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900 dark:text-white">
                      {user.role}
                    </div>
                    <button
                      type="button"
                      className="font-semibold text-purple-600 hover:text-purple-500"
                    >
                      Update
                    </button>
                  </dd>
                </div>
              </dl>
            </div>
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
                Language and dates
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-500">
                Choose what language and date format to use throughout your
                account.
              </p>

              <dl className="mt-6 space-y-6 divide-y divide-gray-100 border-t border-gray-200 text-sm leading-6">
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                    Language
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900 dark:text-white">English</div>
                    <button
                      type="button"
                      className="font-semibold text-purple-600 hover:text-purple-500"
                    >
                      Update
                    </button>
                  </dd>
                </div>
                <div className="pt-6 sm:flex">
                  <dt className="font-medium text-gray-900 dark:text-white sm:w-64 sm:flex-none sm:pr-6">
                    Date format
                  </dt>
                  <dd className="mt-1 flex justify-between gap-x-6 sm:mt-0 sm:flex-auto">
                    <div className="text-gray-900 dark:text-white">
                      DD-MM-YYYY
                    </div>
                    <button
                      type="button"
                      className="font-semibold text-purple-600 hover:text-purple-500"
                    >
                      Update
                    </button>
                  </dd>
                </div>
                <Switch.Group as="div" className="flex pt-6">
                  <Switch.Label
                    as="dt"
                    className="flex-none pr-6 font-medium text-gray-900 dark:text-white sm:w-64"
                    passive
                  >
                    Automatic timezone
                  </Switch.Label>
                  <dd className="flex flex-auto items-center justify-end">
                    <Switch
                      checked={automaticTimezoneEnabled}
                      onChange={setAutomaticTimezoneEnabled}
                      className={classNames(
                        automaticTimezoneEnabled
                          ? 'bg-purple-600'
                          : 'bg-gray-200',
                        'flex w-8 cursor-pointer rounded-full p-px ring-1 ring-inset ring-gray-900/5 transition-colors duration-200 ease-in-out focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600'
                      )}
                    >
                      <span
                        aria-hidden="true"
                        className={classNames(
                          automaticTimezoneEnabled
                            ? 'translate-x-3.5'
                            : 'translate-x-0',
                          'h-4 w-4 transform rounded-full bg-white shadow-sm ring-1 ring-gray-900/5 transition duration-200 ease-in-out'
                        )}
                      />
                    </Switch>
                  </dd>
                </Switch.Group>
              </dl>
            </div>
          </div>
          <div className="p-8 py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:pt-5">
            <dt className="text-sm font-medium text-gray-900 dark:text-white">
              Profile Photo
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 dark:text-white sm:col-span-2 sm:mt-0">
              <span className="flex-grow">
                <span className="relative inline-block">
                  <Gravatar
                    className="h-14 w-14 rounded-full bg-gray-50"
                    email={user?.email}
                    default="monsterid"
                    rating="pg"
                  />
                  <span className="absolute bottom-0 right-0 block h-2.5 w-2.5 rounded-full bg-green-400 ring-2 ring-white" />
                </span>
              </span>
              <span className="ml-4 flex flex-shrink-0 items-start space-x-4">
                <button
                  type="button"
                  className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Update
                </button>
                <span className="text-gray-300" aria-hidden="true">
                  |
                </span>
                <button
                  type="button"
                  className="rounded-md font-medium text-purple-600 hover:text-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2"
                >
                  Remove
                </button>
              </span>
            </dd>
          </div>
        </div>
      </div>
    </BuyerLayout>
  )
}
export default Settings
