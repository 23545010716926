import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'

const LeadsSellerPage = () => {
  return (
    <SellerLayout>
      <BreadCrumb title="Leads Management" url="/admin/" />
      <Panel
        title="Leads "
        description="View and manage permissions."
        url="/admin/leads/new"
      />
    </SellerLayout>
  )
}
export default LeadsSellerPage
