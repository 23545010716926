import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getAccountStatus } from '../../../store/stripe/stripeReducer'
import { CurrencyDollarIcon } from '@heroicons/react/20/solid'
import { payoutSettings } from '../../../store/stripe/stripeService'
import { toast } from 'react-hot-toast'
import { Link } from 'react-router-dom'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import { RootState } from '../../../store'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import Panel from '../../../components/Panel/Index'

const Connected = () => {
  const auth = useSelector(
    (state: RootState) =>
      state.auth || JSON.parse(localStorage.getItem('persist:auth') || '{}')
  )

  const { accountBalance } = useSelector((state: RootState) => state.stripe)
  const dispatch = useDispatch()

  // Ensure safe access to accountBalance arrays
  const pendingAmount = accountBalance?.pending?.[0]?.amount || 0
  const pendingCurrency = accountBalance?.pending?.[0]?.currency || 'USD'

  const availableAmount = accountBalance?.available?.[0]?.amount || 0
  const availableCurrency = accountBalance?.available?.[0]?.currency || 'USD'

  const instantAvailableAmount =
    accountBalance?.instant_available?.[0]?.amount || 0
  const instantAvailableCurrency =
    accountBalance?.instant_available?.[0]?.currency || 'USD'

  const cards = [
    {
      name: 'Pending balance',
      href: '#',
      icon: CurrencyDollarIcon,
      amount: pendingAmount,
      currency: pendingCurrency,
    },
    {
      name: 'Available balance',
      href: '#',
      icon: CurrencyDollarIcon,
      amount: availableAmount,
      currency: availableCurrency,
    },
    {
      name: 'Instant Available balance',
      href: '#',
      icon: CurrencyDollarIcon,
      amount: instantAvailableAmount,
      currency: instantAvailableCurrency,
    },
  ]

  useEffect(() => {
    if (auth?.user?.stripe_seller?.charges_enabled) {
      dispatch(getAccountStatus(auth.token))
    }
  }, [auth, dispatch])

  const handlePayoutSettings = async (e) => {
    e.preventDefault()
    try {
      const response = await payoutSettings(auth.token)
      window.location.href = response.data.url
    } catch (error) {
      toast.error(error.message)
    }
  }

  return (
    <SellerLayout>
      <BreadCrumb
        title="Seller Overview"
        url={`/seller/${auth?.user?.username}`}
      />
      <Panel
        title="Seller Overview"
        description="View your seller overview"
        url="#"
        icon={CurrencyDollarIcon}
      >
        <div className="">
          <div className="mt-2 px-4 py-4 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
            {/* Card */}
            {cards.map((card) => (
              <div
                key={card.name}
                className="overflow-hidden rounded-lg bg-white dark:bg-gray-950 dark:border border-white shadow"
              >
                <div className="p-5">
                  <div className="flex items-center">
                    <div className="flex-shrink-0">
                      <card.icon
                        className="h-6 w-6 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="ml-5 w-0 flex-1">
                      <dl>
                        <dt className="truncate text-sm font-medium dark:text-white text-gray-500">
                          {card.name}
                        </dt>
                        <dd>
                          <div className="text-lg font-medium dark:text-white text-gray-900">
                            {card.currency?.toUpperCase()} ${card.amount}
                          </div>
                        </dd>
                      </dl>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 dark:bg-gray-950 px-5 py-3">
                  <div className="text-sm">
                    <Link
                      to={card.href}
                      className="font-medium text-purple-700 dark:text-white hover:text-purple-900"
                    >
                      View More
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-end items-end">
            <div className="order-last px-4 flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              <Link
                to="#"
                onClick={handlePayoutSettings}
                className="mr-auto flex items-center gap-x-1 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                Payout Settings
              </Link>
              <Link
                to="/seller/stripe/callback"
                className="ml-auto flex items-center gap-x-1 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-600"
              >
                Refresh Balance
              </Link>
            </div>
          </div>
        </div>
      </Panel>
    </SellerLayout>
  )
}
export default Connected
