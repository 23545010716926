import BreadCrumb from '../../../../components/BreadCrumb/Index'
import SellerLayout from '../../../../components/Layout/SellerLayout/Index'
import Panel from '../../../../components/Panel/Index'

const CreateProposal = () => {
  return (
    <SellerLayout>
      <BreadCrumb title="Apply for the project" />
      <Panel
        title="Apply for the project"
        description="Please reply here"
      ></Panel>
    </SellerLayout>
  )
}
export default CreateProposal
