import { useSelector } from 'react-redux'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import { RootState } from '../../../store'
import {
  PlusCircleIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline'
import Panel from '../../../components/Panel/Index'

// Sample data: replace this with actual API data
const sampleTasks = [
  {
    id: 1,
    title: 'Website Design',
    description: 'Design a professional website',
    price: 500,
    status: 'Active',
  },
  {
    id: 2,
    title: 'Logo Creation',
    description: 'Create a logo for your brand',
    price: 150,
    status: 'Sold',
  },
  {
    id: 3,
    title: 'SEO Optimization',
    description: 'Boost your website rankings',
    price: 300,
    status: 'Pending',
  },
  {
    id: 4,
    title: 'Social Media Marketing',
    description: 'Manage social media accounts',
    price: 250,
    status: 'Active',
  },
  {
    id: 5,
    title: 'Email Marketing',
    description: 'Set up an email campaign',
    price: 100,
    status: 'Pending',
  },
  {
    id: 6,
    title: 'Video Editing',
    description: 'Edit promotional videos',
    price: 200,
    status: 'Sold',
  },
  {
    id: 7,
    title: 'Content Writing',
    description: 'Write blog articles and web content',
    price: 120,
    status: 'Active',
  },
  {
    id: 8,
    title: 'Graphic Design',
    description: 'Design marketing materials',
    price: 180,
    status: 'Pending',
  },
  {
    id: 9,
    title: 'App Development',
    description: 'Develop a mobile application',
    price: 1000,
    status: 'Active',
  },
]

const Tasks = () => {
  const { user } = useSelector((state: RootState) => state.auth)

  return (
    <SellerLayout>
      {/* BreadCrumb */}
      <BreadCrumb title="Tasks" url={`/seller/${user?.username}`} />

      {/* Panel Header */}
      <Panel
        title="Tasks"
        description="Tasks you have created"
        icon={PlusCircleIcon}
        url={`/seller/tasks/new`}
      >
        {/* Task List Section */}
        <div className="max-w-7xl mx-auto mt-8 p-4">
          {/* Task Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {sampleTasks.map((task) => (
              <div
                key={task.id}
                className="bg-white dark:bg-gray-800 shadow rounded-lg p-6"
              >
                <h3 className="text-lg font-semibold text-gray-900 dark:text-white">
                  {task.title}
                </h3>
                <p className="text-gray-600 dark:text-gray-400 mt-2">
                  {task.description}
                </p>
                <div className="mt-4 text-lg font-bold text-indigo-600">
                  ${task.price}
                </div>
                <div
                  className={`mt-2 inline-flex px-3 py-1 rounded-full text-sm font-semibold ${
                    task.status === 'Active'
                      ? 'bg-green-100 text-green-700'
                      : task.status === 'Sold'
                      ? 'bg-gray-100 text-gray-700'
                      : 'bg-yellow-100 text-yellow-700'
                  }`}
                >
                  {task.status}
                </div>

                {/* Action Buttons */}
                <div className="mt-4 flex space-x-2">
                  <a
                    href={`/seller/tasks/edit/${task.id}`}
                    className="inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-700 bg-gray-200 dark:bg-gray-700 dark:text-white rounded-md hover:bg-gray-300 dark:hover:bg-gray-600"
                  >
                    <PencilSquareIcon className="h-4 w-4 mr-1" />
                    Edit
                  </a>
                  <button className="inline-flex items-center px-3 py-2 text-sm font-semibold text-red-700 bg-red-100 rounded-md hover:bg-red-200">
                    <TrashIcon className="h-4 w-4 mr-1" />
                    Delete
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* If no tasks are available */}
          {sampleTasks.length === 0 && (
            <p className="text-center text-gray-600 dark:text-gray-400 mt-8">
              You have not created any tasks yet.{' '}
              <a
                href="/seller/tasks/new"
                className="text-indigo-600 hover:underline"
              >
                Create a new task
              </a>
              .
            </p>
          )}
        </div>
      </Panel>
    </SellerLayout>
  )
}

export default Tasks
