import SellerLayout from '../../../../components/Layout/SellerLayout/Index'
import { useCookies } from 'react-cookie'
import BreadCrumb from '../../../../components/BreadCrumb/Index'
import Panel from '../../../../components/Panel/Index'

const CreateTask = () => {
  const [cookies] = useCookies()

  console.log(cookies)

  return (
    <SellerLayout>
      <BreadCrumb title="Create Task" url={`/seller/tasks`} />
      <Panel title="Create Task" description="Create a new task."></Panel>
    </SellerLayout>
  )
}
export default CreateTask
