import BreadCrumb from '../../../components/BreadCrumb/Index'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import Panel from '../../../components/Panel/Index'

const SellerHome = () => {
  return (
    <SellerLayout>
      <BreadCrumb title="Seller Home" />
      <Panel
        title="Seller Home"
        description="Seller Home"
        url="/seller/home"
      ></Panel>
    </SellerLayout>
  )
}
export default SellerHome
