import { useDispatch, useSelector } from 'react-redux'
import { HandThumbDownIcon, HeartIcon } from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import { RootState } from '../../../store'
import BreadCrumb from '../../../components/BreadCrumb/Index'
import SidePanel from '../../../components/SidePanel/Index'
import {
  getAllSellerProjects,
  likeProject,
} from '../../../store/sellerProject/sellerProjectReducer'
import he from 'he'
import Panel from '../../../components/Panel/Index'
import DOMPurify from 'dompurify'
import NoProjectsSVG from '../../../assets/images/svg/icon-01.svg'

const SellerProjects = () => {
  const [selectedProjectId, setSelectedProjectId] = useState(null) // Track the currently selected project
  const [selectedTab, setSelectedTab] = useState('Trending') // Track active tab
  const { user } = useSelector((state: RootState) => state.auth)
  const { projects } = useSelector((state: RootState) => state.sellerProjects)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllSellerProjects()) // Fetch projects on mount
  }, [dispatch])

  const tabs = [
    { name: 'Recently Posted', key: 'recent' },
    { name: 'Trending', key: 'trending' },
    { name: 'Most Liked', key: 'liked' },
  ]

  const filterProjects = (projects: any[], tab: string) => {
    switch (tab) {
      case 'recent':
        return projects.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      case 'trending':
        return projects.filter((project) => project.isTrending)
      case 'liked':
        return projects.sort((a, b) => b.likes - a.likes)
      default:
        return projects
    }
  }

  const handleLike = (projectId) => {
    dispatch(likeProject(projectId))
  }

  const handleOpenDetails = (projectId) => {
    setSelectedProjectId(projectId)
  }

  const handleCloseDetails = () => {
    setSelectedProjectId(null)
  }

  const filteredProjects = filterProjects(projects, selectedTab.toLowerCase())

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  return (
    <SellerLayout>
      <BreadCrumb title="Projects" url={`/seller/${user?.username}`} />
      <Panel title="Projects" description="Here you can view all your projects">
        <main className="lg:col-span-9 xl:col-span-6">
          <div className="px-4 py-4 sm:px-0">
            <div className="sm:hidden">
              <label htmlFor="project-tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="project-tabs"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-white dark:bg-gray-950 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-purple-500"
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value)}
              >
                {tabs.map((tab) => (
                  <option key={tab.key} value={tab.name}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <nav
                className="isolate flex divide-x divide-gray-200 rounded-lg shadow"
                aria-label="Tabs"
              >
                {tabs.map((tab, tabIdx) => (
                  <button
                    key={tab.name}
                    onClick={() => setSelectedTab(tab.name)}
                    className={classNames(
                      tab.name === selectedTab
                        ? 'text-gray-900 dark:text-white'
                        : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white dark:bg-gray-950 py-4 px-6 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                    )}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.name === selectedTab
                          ? 'bg-purple-500'
                          : 'bg-transparent',
                        'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </button>
                ))}
              </nav>
            </div>
          </div>
          <div className="mt-4 px-4">
            <h1 className="sr-only">{selectedTab} Projects</h1>
            <div className="space-y-2">
              {filteredProjects.length > 0 ? (
                filteredProjects.map((project) => (
                  <div
                    key={project._id}
                    className="bg-white dark:bg-gray-950 px-4 py-4 border-2 border-b-2 shadow rounded-lg sm:p-6"
                  >
                    <article aria-labelledby={'project-title-' + project._id}>
                      <div className="block sm:flex justify-between items-center">
                        <div className="py-4">
                          <h2
                            id={'project-title-' + project._id}
                            className="text-lg font-medium dark:text-white text-gray-900"
                          >
                            {project.title}
                          </h2>
                        </div>
                        <div className="py-4">
                          <div className="flex space-x-6">
                            <span className="inline-flex items-center text-sm">
                              <button
                                type="button"
                                className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                              >
                                <HandThumbDownIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">dislikes</span>
                              </button>
                            </span>
                            <span className="inline-flex items-center text-sm">
                              <button
                                type="button"
                                onClick={() => handleLike(project._id)}
                                className="inline-flex space-x-2 text-gray-400 hover:text-gray-500"
                              >
                                <HeartIcon
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                                <span className="sr-only">likes</span>
                              </button>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mt-2 space-y-4 text-md dark:text-white text-gray-700 truncate overflow-hidden"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            he.decode(project.description)
                          ).substring(0, 200),
                        }}
                      />
                      <div className="mt-4 block xs:flex justify-between items-center">
                        <div>
                          <p className="mt-2 text-md dark:px-2 dark:py-1 font-medium dark:bg-white text-gray-900 rounded-md">
                            ${project.budget} USD
                          </p>
                        </div>
                        <div>
                          <button
                            className="mt-2 mr-2 rounded-md bg-purple-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-purple-500"
                            onClick={() => handleOpenDetails(project._id)} // Open details for the selected project
                          >
                            Show details
                          </button>
                        </div>
                      </div>
                      <SidePanel
                        isOpen={selectedProjectId === project._id} // Open panel for the selected project
                        setIsOpen={handleCloseDetails} // Close the panel
                        project={project}
                      />
                    </article>
                  </div>
                ))
              ) : (
                <div className="flex flex-col items-center">
                  <img
                    src={NoProjectsSVG}
                    alt="No Projects"
                    className="h-64 w-auto"
                  />
                  <p className="text-gray-600 dark:text-gray-400 mt-4">
                    No projects found.
                  </p>
                </div>
              )}
            </div>
          </div>
        </main>
      </Panel>
    </SellerLayout>
  )
}

export default SellerProjects
