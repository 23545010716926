import Layout from '../../../components/Layout/Index'
import { useState } from 'react'
import { RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/20/solid'

const mailingLists = [
  {
    id: 1,
    title: 'Client',
    description: 'I’m a client, hiring for a project',
    users: '621 users',
  },
  {
    id: 2,
    title: 'Freelancer',
    description: 'I’m a freelancer, looking for work',
    users: '1200 users',
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

// Note: User Onboarding Page is not yet implemented
const Onboarding = () => {
  const [selectedMailingLists, setSelectedMailingLists] = useState(
    mailingLists[0]
  )
  return (
    <Layout>
      <div className="sticky top-0 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <h1 className="text-2xl font-bold text-gray-700 ml-4">Onboarding</h1>
        </div>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <RadioGroup
          value={selectedMailingLists}
          onChange={setSelectedMailingLists}
        >
          <RadioGroup.Label className="text-base font-semibold leading-6 text-gray-900">
            Join as a client or freelancer
          </RadioGroup.Label>

          <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8">
            {mailingLists.map((mailingList) => (
              <RadioGroup.Option
                key={mailingList.id}
                value={mailingList}
                className={({ active }) =>
                  classNames(
                    active
                      ? 'border-purple-600 ring-2 ring-purple-600'
                      : 'border-gray-300',
                    'relative flex cursor-pointer rounded-lg border bg-white p-4 shadow-sm focus:outline-none'
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className="flex flex-1">
                      <span className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className="block text-sm font-medium text-gray-900"
                        >
                          {mailingList.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="mt-1 flex items-center text-sm text-gray-500"
                        >
                          {mailingList.description}
                        </RadioGroup.Description>
                        <RadioGroup.Description
                          as="span"
                          className="mt-6 text-sm font-medium text-gray-900"
                        >
                          {mailingList.users}
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? 'invisible' : '',
                        'h-5 w-5 text-purple-600'
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-purple-600' : 'border-transparent',
                        'pointer-events-none absolute -inset-px rounded-lg'
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
      </div>
    </Layout>
  )
}
export default Onboarding
