import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Spinner from '../../../components/Spinner/Index'
import { toast } from 'react-hot-toast'
import SellerLayout from '../../../components/Layout/SellerLayout/Index'
import { RootState } from '../../../store'
import { getStripeStatus } from '../../../store/auth/authReducer'

const AccountStatus = () => {
  const dispatch = useDispatch()
  const { user, token, isLoading, isSuccess, isError } = useSelector(
    (state: RootState) =>
      state.auth || JSON.parse(localStorage.getItem('persist:auth') || '{}')
  )

  console.log(isSuccess)

  useEffect(() => {
    if (user && token) accountStatus()
  }, [user])

  const accountStatus = async () => {
    try {
      dispatch(getStripeStatus())

      setTimeout(() => {
        if (isSuccess) {
          window.location.href = '/seller/stripe/connect'
        } else if (isError) {
          toast.error('Something went wrong, please try again')
        }
      }, 5000) // Delay of 5000 milliseconds (5 seconds)

      // if (response) {
      //     window.location.href = '/seller/stripe/connect'
      // }
      // const { data } = await statusStripe()
      // updateUserInLocalStorage(data, () => {
      //     window.location.href = '/seller/stripe/connect'
      // })
    } catch (error) {
      console.log(error.message)
      toast.error('Something went wrong, please try again')
    }
  }

  if (isLoading) return <Spinner> Loading...</Spinner>

  return (
    <SellerLayout>
      <Spinner>Loading account status...</Spinner>
    </SellerLayout>
  )
}
export default AccountStatus
