import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-hot-toast'
import { connectStripe } from '../../store/stripe/stripeService'
import { useEffect, useState } from 'react'
import { getAccountStatus } from '../../store/stripe/stripeReducer'
import Connected from './Connected/Index'
import BuyerLayout from '../../components/Layout/BuyerLayout/Index'
import BreadCrumb from '../../components/BreadCrumb/Index'
import Panel from '../../components/Panel/Index'

const Stripe = () => {
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  const auth = useSelector(
    (state) =>
      state.auth || JSON.parse(localStorage.getItem('presist:root') || '{}')
  )

  useEffect(() => {
    if (auth?.user?.stripe_seller?.charges_enabled) {
      dispatch(getAccountStatus(auth.token))
    }
  }, [])

  const handeClick = async () => {
    setLoading(true)
    try {
      const response = await connectStripe(auth.token)
      window.location.href = response.data
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }
  }

  const notConnected = () => (
    <BuyerLayout>
      <BreadCrumb title="Payments" url={`/buyer/${auth.user?.username}`} />
      <Panel
        title="Payments"
        description="Connect to Stripe to receive payouts."
        url={`/buyer/${auth.user?.username}`}
      >
        <h1 className="text-xl dark:text-white text-gray-700 font-semibold py-2">
          Connect to Stripe to receive payouts
        </h1>
        {loading ? (
          <button
            className="bg-purple-500 text-white inline-flex items-center py-3 px-3 rounded-md"
            disabled
          >
            <div
              className="inline-block h-8 w-8 animate-spin mr-2 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
              role="status"
            >
              <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                Loading...
              </span>
            </div>
            Processing
          </button>
        ) : (
          <>
            <button
              className="bg-purple-500 text-lg text-white py-3 px-4 rounded-lg"
              onClick={handeClick}
            >
              Connect with Stripe
            </button>
          </>
        )}
      </Panel>
    </BuyerLayout>
  )

  const checkStatus = () => (
    <BuyerLayout>
      <div className="sticky top-0 flex h-20 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
        <div className="flex items-center">
          <a className="" href={`/seller/${auth.user?.username}`}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-8 h-8"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18"
              />
            </svg>
          </a>
          <h1 className="text-2xl font-bold text-gray-700 ml-4">Payments</h1>
        </div>
      </div>
      <div className="max-w-7xl mx-auto">
        <div className="flex flex-col justify-center items-center mt-96">
          <h1 className="text-xl font-semibold py-2">
            It appears you have not completed your Stripe integration. Click the
            button below to continue your Stripe integration.
          </h1>
          {loading ? (
            <button
              className="bg-purple-500 text-white inline-flex items-center py-3 px-3 rounded-md"
              disabled
            >
              <div
                className="inline-block h-8 w-8 animate-spin mr-2 rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                role="status"
              >
                <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                  Loading...
                </span>
              </div>
              Processing
            </button>
          ) : (
            <button
              className="bg-purple-500 text-lg text-white py-3 px-4 rounded-lg"
              onClick={handeClick}
            >
              Check Status
            </button>
          )}
        </div>
      </div>
    </BuyerLayout>
  )

  return (
    <>
      {auth &&
      auth.user &&
      auth.user.stripe_seller &&
      auth.user.stripe_seller.charges_enabled ? (
        <Connected />
      ) : auth.user?.stripe_seller?.charges_enabled === false ? (
        checkStatus()
      ) : (
        notConnected()
      )}
    </>
  )
}
export default Stripe
